import posthog from 'posthog-js';
import type { PostHogConfig } from 'posthog-js';

type Config = {
	key: string;
} & Partial<PostHogConfig>;

export function createClient(config: Config) {
	const { key, ...settings } = config;
	const ph = posthog.init(config.key, {
		api_host: config.api_host ?? 'https://app.posthog.com',
		ui_host: 'https://us.posthog.com',
		persistence: 'localStorage',
		person_profiles: 'identified_only',
		disable_surveys: true,
		disable_session_recording: true,
		// Prevent extra feature flag requests after the browser window is opened
		// This makes the client fully rely on the server-side calculated flags
		advanced_disable_feature_flags_on_first_load: true,
		...settings,
	})!;
	return ph;
}
