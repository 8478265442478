export const ignoreErrors = [
	/resizeobserver loop completed/i,
	/transition was (aborted|skipped)/i,
	/view transition/,
	'Attempted to assign to readonly property',
	/Invalid `prisma\./i,
	/fetch is aborted/i,
	/failed to fetch/i,
	/(load|fetch) failed/i,
];
